import firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyBMaPgykgVlcIhDwPm8iv34iwle6TZJZVA',
  authDomain: 'micro-pi.firebaseapp.com',
  databaseURL: 'https://micro-pi-default-rtdb.firebaseio.com',
  projectId: 'micro-pi',
  storageBucket: 'micro-pi.appspot.com',
  messagingSenderId: '364835617529',
  appId: '1:364835617529:web:1d6be0f795f74068d28028',
  measurementId: 'G-WTGVP5630B',
};

const nme = firebase.initializeApp(firebaseConfig);
console.log('nme', nme);

export const dbRef = firebase.database();

export default firebase;
