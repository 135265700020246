import { dbRef } from './fbConfig';

const ledsRef = dbRef.ref('/leds');

export const led1Ref = dbRef.ref('/leds').child('led1');
// const led2Ref = dbRef.ref('/leds').child('led2').child('status');
// const led3Ref = dbRef.ref('/leds').child('led3').child('status');

export function listenForChanges(setLedStatus) {
  console.log('listenForchanges');
  ledsRef.on('child_changed', function (data) {
    console.log('child changed', data.key, data.val().status);
    // data.key, data.val().title, data.val().description
    setLedStatus(data.val().status);
  });

  ledsRef.on('child_added', function (data) {
    console.log('child added', data.key, data.val().status);
  });
}

export function stopListenForChanges() {
  ledsRef.off('child_changed');
  ledsRef.off('child_added');
}

export function populate() {
  console.log('==========led populate');
  var ledsRef = dbRef.ref('/leds');
  ledsRef.set({
    led1: {
      status: 'OFF',
    },
    led2: {
      status: 'ON',
    },
  });
}

export async function readLedStatus(ledRef) {
  const data = await ledRef.once('value');
  return data.val().status;
}

export function updateLedStatus(ledRef, value) {
  ledRef.update({
    status: value,
  });
}

//   console.log('==========done');
// }
// function test() {
//   console.log('==========led DELETE test');
//   var ledsRef = dbRef.ref('/leds');
//   ledsRef.child('led2').remove();

//   console.log('==========done');
// }
// return (
//   <div className="App">
//     <header className="App-header">
//       <p>This is a skeletal page to support Micro-Pi</p>
//       <button onClick={test}>click me</button>
//     </header>
//   </div>
// );

// function test() {
//   console.log('==========led READ test');
//   var ledsRef = dbRef.ref('/leds').child('led1');

//   ledsRef.get().then(
//     (snapshot) => {
//       console.log('again');
//       console.log(snapshot.val());
//     },
//     (errorObject) => {
//       console.log('The read failed: ' + errorObject.name);
//     }
//   );
//   console.log('==========done');
// }

// function test() {
//   console.log('==========led READ all test');
//   var ledsRef = dbRef.ref('/leds');
//   var tutorials = [];
//   ledsRef.once('value', function (snapshot) {
//     snapshot.forEach(function (snapshot) {
//       var key = snapshot.key;
//       var data = snapshot.val();
//       tutorials.push({ key: key, status: data.status });
//     });
//   });
//   console.log(tutorials);
//   console.log('==========done');
// }

// function test() {
//   console.log('======test');
//   var tutorialsRef = dbRef.ref('/tutorials');
//   tutorialsRef.child('key one').set({
//     title: 'zkoder Tut#1',
//     description: 'Tut#1 Description',
//   });
//   console.log('====== done');
// }
