import { useState, useEffect } from 'react';
import './App.css';
import {
  listenForChanges,
  stopListenForChanges,
  populate,
  led1Ref,
  updateLedStatus,
  readLedStatus,
} from './firebase/fbSupport';

function App() {
  const [ledOn, setLedOn] = useState(false);

  useEffect(() => {
    console.log('use effect started');

    async function getStatus() {
      const status = await readLedStatus(led1Ref);
      console.log('useEffect Status:', status);
      setLedStatus(status);
    }
    getStatus();

    listenForChanges(setLedStatus);
    // populate();

    return () => {
      console.log('useEffect stopping');
      stopListenForChanges();
    };
  }, []);

  async function btnClick() {
    if (ledOn) {
      updateLedStatus(led1Ref, 'OFF');
      setLedOn(false);
    } else {
      updateLedStatus(led1Ref, 'ON');
      setLedOn(true);
    }
  }

  function setLedStatus(ledStatus) {
    console.log('ledStatus: ', ledStatus);
    if (ledStatus === 'OFF') {
      setLedOn(false);
    } else {
      setLedOn(true);
    }
  }

  return (
    <div className="App">
      <h3>Led: {ledOn ? ' on' : ' off'}</h3>
      <button
        onClick={btnClick}
        className={ledOn ? 'ButtonGreen' : 'ButtonRed'}
      >
        {ledOn ? 'Turn OFF' : 'Turn ON'}
      </button>
    </div>
  );
}

export default App;
